<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <div class="row">
                        <div class="col-6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Batch" vid="batch_no" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="batch_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.batch_no"
                                    :options="batchList"
                                    id="batch_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Resource Person" vid="trainer_id" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="trainer_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.trainer')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.trainer_id"
                                    :options="trainerList"
                                    id="trainer_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        <!-- <div class="col-6">
                          <ValidationProvider name="Name" vid="name"  rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="name"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="name"
                                v-model="formData.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.name')"
                                readonly
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div> -->
                        <div class="col-6">
                          <ValidationProvider name="Mobile" vid="mobile"  rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="mobile"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('globalTrans.mobile')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="mobile"
                                v-model="formData.mobile"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.mobile')"
                                readonly
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                          <ValidationProvider name="Email" vid="Email" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="Email"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="email"
                                v-model="formData.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.email')"
                                readonly
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                          <ValidationProvider name="Start Date" vid="start_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="start_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.start_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                          <ValidationProvider name="End Date" vid="end_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="end_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.end_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback d-block" v-if="dateErrorMsg">
                                {{ dateErrorMsg }}
                              </div>
                              <div class="invalid-feedback" v-else>
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <b-col sm="6" v-if="formData.attend === 1 && formData.gender === 2">
                          <ValidationProvider name="Number Of Attend" vid='number_attend' rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="mobile"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalTraining.number_attend')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                id="number_attend"
                                v-model="formData.number_attend"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col sm="6" v-if="formData.attend === 1 && formData.gender === 2">
                          <ValidationProvider name="Number Of Attend" vid='attend_details'>
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="attend_details"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalTraining.attend_details')}}
                              </template>
                              <b-form-textarea
                                id="attend_details"
                                v-model="formData.attend_details"
                                disabled
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <div class="col-6">
                          <ValidationProvider name="Guest House Name" vid="guest_house_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="guest_house_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.guest_house_name')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.guest_house_id"
                                :options="guestHouseList"
                                id="guest_house_id"
                                @change="getRent()"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-12 text-center mb-2 text-success" v-if="roombooklist.length > 0">
                          <b>{{ $t('elearning_config.booked_room_history') }}</b>
                        </div>
                        <div class="col-12" v-if="roombooklist.length > 0">
                          <div class="table-wrapper table-responsive">
                            <table class="table table-striped table-hover table-bordered">
                              <thead>
                                  <tr>
                                    <td style="width: 15%;"> <b>{{ $t('elearning_config.room_type') }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ $t('elearning_config.floor_no') }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ $t('elearning_config.room_no') }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ $t('globalTrans.start_date') }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ $t('globalTrans.end_date') }}</b> </td>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in roombooklist" :key="index">
                                    <td style="width: 15%;"> <b>{{ getRoomType(item.room_type_id) }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ getFloorName(item.floor_no) }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ getRoom(item.room_id) }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ item.start_date }}</b> </td>
                                    <td style="width: 15%;"> <b>{{ item.end_date }}</b> </td>
                                  </tr>
                              </tbody>
                          </table>
                          </div>
                        </div>
                        <div class="col-6">
                          <ValidationProvider name="Room Type" vid="room_type_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="room_type_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.room_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.room_type_id"
                                :options="roomTypeList"
                                id="room_type_id"
                                @change="getRent()"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <!-- <div class="col-6" v-if="formData.registration_for != 2 && formData.registration_for != 1">
                          <ValidationProvider name="User Type" vid="user_type_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="user_type_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.user_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.user_type_id"
                                :options="userPriceTypeList"
                                id="user_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div> -->
                        <!-- <div class="col-6" v-if="formData.registration_for != 2 && formData.registration_for != 1">
                          <ValidationProvider name="Room Rent" vid="room_rent_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="room_rent_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.room_rent')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="room_rent"
                                v-model="formData.room_rent"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('elearning_config.room_rent')"
                                readonly
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div> -->
                        <div class="col-6">
                          <ValidationProvider name="Floor No" vid="floor_no" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="room_rent_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.floor_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.floor_no"
                                :options="floorlist"
                                id="room_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                          <ValidationProvider name="Room No" vid="room_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="room_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.room_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.room_id"
                                :options="roomlist"
                                id="room_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                        <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remarks"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.remarks_en')}}
                            </template>
                            <b-form-textarea
                                rows="2"
                                id="remarks"
                                v-model="formData.remarks_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                          <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.remarks_bn')}}
                              </template>
                              <b-form-textarea
                                  rows="2"
                                  id="remarks_bn"
                                  v-model="formData.remarks_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">
                            <slot v-if="formData.id">
                              {{ $t('elearning_iabm.booked') }}
                            </slot>
                            <slot v-else>
                              {{ $t('elearning_iabm.book') }}
                            </slot>
                          </b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{formData}}</pre> -->
      <!-- <pre>{{userPriceTypeList}}</pre>
      <pre>{{$store.state.TrainingElearning.commonObj.roomRentList}}</pre> -->
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { bookingApplicationStore, bookingApplicationCheck, roomRentList, roomList, circularList, roomBookedList } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData.training_application_id = tmp.id
      this.formData.fiscal_year_id = tmp.fiscal_year_id
      this.formData.training_type_id = tmp.training_type_id
      this.formData.training_category_id = tmp.training_category_id
      this.formData.training_title_id = tmp.training_title_id
      this.formData.circular_memo_no = tmp.circular_memo_no
      this.formData.batch_no = tmp.batch_no
      this.formData.org_id = tmp.org_id
      this.formData.trainer_id = tmp.personal_info_id
      this.formData.office_type_id = tmp.office_type_id
      this.formData.office_id = tmp.office_id
      this.formData.name = this.$i18n.locale === 'bn' ? tmp.name_bn : tmp.name
      this.formData.email = tmp.email
      this.formData.gender = tmp.gender
      this.formData.registration_for = tmp.registration_for
      this.formData.attend = tmp.attend
      this.formData.number_attend = tmp.number_attend
      this.formData.attend_details = tmp.attend_details
      this.formData.mobile = tmp.mobile
      // this.formData.start_date = tmp.training_start_date
      // this.formData.end_date = tmp.training_end_date
      if (tmp.booking && tmp.booking.status === 1) {
        this.formData.id = tmp.booking.id
        this.formData.fiscal_year_id = tmp.booking.fiscal_year_id
        this.formData.start_date = tmp.booking.start_date
        this.formData.end_date = tmp.booking.end_date
        this.formData.room_type_id = tmp.booking.room_type_id
        this.formData.room_rent_id = tmp.booking.room_rent_id
        this.formData.guest_house_id = tmp.booking.guest_house_id
        this.formData.user_type_id = tmp.booking.user_type_id
        this.formData.room_id = tmp.booking.room_id
        this.formData.floor_no = tmp.booking.floor_no
        this.formData.remarks_en = tmp.booking.remarks_en
        this.formData.remarks_bn = tmp.booking.remarks_bn
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      loading: false,
      circularLoading: false,
      trainerLoading: false,
      formData: {
        id: '',
        name: '',
        email: '',
        gender: 0,
        attend: 0,
        mobile: '',
        number_attend: '',
        attend_details: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        batch_no: 0,
        circular_memo_no: '',
        office_id: 0,
        org_id: 0,
        guest_house_id: 0,
        room_type_id: 0,
        user_type_id: 0,
        room_rent_id: 0,
        floor_no: '',
        room_id: 0,
        trainer_id: 0,
        user_id: 0
      },
      officeTypeList: [],
      officeList: [],
      guestHouseList: [],
      roomTypeList: [],
      roomlist: [],
      floorlist: [],
      roombooklist: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingTitleList: [],
      batchList: [],
      circularList: [],
      trainingTypeList: [],
      allBatchListData: [],
      // allBatchList: [],
      trainerListData: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    trainerList: function () {
      const listObject = this.trainerListData
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    roomRentList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1)
    },
    userPriceTypeList: function () {
      if (this.formData.org_id !== 9) {
        return this.$store.state.TrainingElearning.commonObj.userPriceTypeList
      } else {
        return this.$store.state.TrainingElearning.commonObj.userRentTypeList
      }
    }
  },
  watch: {
    'formData.org_id': function (newValue, oldValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
      this.guestHouseList = this.getGuestHouseList(newValue)
      if (newValue !== oldValue) {
        this.trainingTypeList = this.getTypeList(newValue)
      } else {
        this.trainingTypeList = []
      }
    },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCircularList()
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
        this.getAssignedTrainerList()
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    },
    'formData.guest_house_id': function (newValue) {
      this.roomTypeList = this.getRoomTypeList(newValue)
      this.getBookedRoomData(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    // 'formData.type_id': function (newValue) {
    //   this.trainingCategoryList = this.getCategoryList(newValue)
    // },
    // 'formData.category_id': function (newValue) {
    //   this.trainingTitleList = this.getTrainingTitleList(newValue)
    // },
    'formData.room_type_id': function (newValue) {
      this.floorlist = this.getFloorList(newValue)
    },
    'formData.user_type_id': function (newValue) {
      this.getRoomRentList(newValue)
    },
    'formData.floor_no': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getRoomList(newValue)
      }
    },
    // 'formData.start_date': function (newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.getRoomList(newValue)
    //   }
    // },
    // 'formData.end_date': function (newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.getRoomList(newValue)
    //   }
    // },
    'formData.trainer_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.trainerListData.find(allBatchListData => parseInt(allBatchListData.value) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.user_id = allBatchListData.user_id
          this.formData.mobile = allBatchListData.mobile
          this.formData.email = allBatchListData.email
        }
        this.getTrainingDataList()
      }
    }
    // 'formData.room_id': function () {
    //   this.bookingApplicationCheck()
    // }
  },
  methods: {
    async getBookedRoomData () {
      this.loading = true
        const params = {
            guest_house_id: this.formData.guest_house_id,
            start_date: this.formData.start_date,
            end_date: this.formData.end_date
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, roomBookedList, params)
          if (result.success) {
            this.roombooklist = result.data
            this.loading = false
          } else {
            this.roombooklist = []
            this.loading = false
          }
    },
    async getTrainingDataList () {
      if (this.formData.trainer_id) {
        this.trainerLoading = true
        const serchData = {
            circular_memo_no: this.formData.circular_memo_no,
            batch_no: this.formData.batch_no,
            trainer_id: this.formData.trainer_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tpm/driver-assign/training-data-list', serchData)
        if (result.success) {
            this.selectDate = result.data
            this.trainerLoading = false
            this.getScheduleTime()
        } else {
            this.selectDate = []
            this.trainerLoading = false
        }
      }
    },
    getScheduleTime () {
      const listObject = this.selectDate.find(allBatchListData => allBatchListData.value === this.formData.training_date)
      if (listObject) {
        // this.start_time = listObject.start_time
        // this.end_time = listObject.end_time
        // this.session_date = listObject.session_date
      } else {
        // this.start_time = ''
        // this.end_time = ''
        // this.session_date = ''
      }
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getGuestHouseList (orgId) {
        return this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    getRoomTypeList (guestHouseId) {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1 && item.guest_house_id === guestHouseId)
    },
    getRoomRentList () {
      const roomRentList = this.$store.state.TrainingElearning.commonObj.roomRentList.find(item => item.status === 1 && item.room_type_id === this.formData.room_type_id)
      this.formData.room_rent_id = typeof roomRentList !== 'undefined' ? roomRentList.value : 0
      if (this.formData.user_type_id === 1) {
        this.formData.room_rent = typeof roomRentList !== 'undefined' ? roomRentList.own_org_price : 0
        this.formData.tax = roomRentList.vat_tax
      } else if (this.formData.user_type_id === 2) {
        this.formData.room_rent = typeof roomRentList !== 'undefined' ? roomRentList.govt_price : 0
        this.formData.tax = roomRentList.vat_tax
      } else if (this.formData.user_type_id === 3) {
        this.formData.room_rent = typeof roomRentList !== 'undefined' ? roomRentList.private_price : 0
        this.formData.tax = roomRentList.vat_tax
      } else if (this.formData.user_type_id === 4) {
        this.formData.room_rent = typeof roomRentList !== 'undefined' ? roomRentList.domestic_foreign_consultants_price : 0
        this.formData.tax = roomRentList.vat_tax
      } else if (this.formData.user_type_id === 5) {
        this.formData.room_rent = typeof roomRentList !== 'undefined' ? roomRentList.nars_grade10_price : 0
        this.formData.tax = roomRentList.vat_tax
      } else if (this.formData.user_type_id === 6) {
        this.formData.room_rent = typeof roomRentList !== 'undefined' ? roomRentList.non_govt_price : 0
        this.formData.tax = roomRentList.vat_tax
      } else if (this.formData.user_type_id === 7) {
        this.formData.room_rent = typeof roomRentList !== 'undefined' ? roomRentList.farmer_price : 0
        this.formData.tax = roomRentList.vat_tax
      }
    },
    async getRoomList (floorId) {
      if (this.formData.room_id) {
        this.roomlist = this.$store.state.TrainingElearning.commonObj.roomList.filter(item => item.status === 1 && item.floor_no === floorId && this.formData.room_type_id === item.room_type_id)
      } else {
        this.loading = true
        const params = {
            guest_house_id: this.formData.guest_house_id,
            room_type_id: this.formData.room_type_id,
            floor_id: floorId,
            start_date: this.formData.start_date,
            end_date: this.formData.end_date
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, roomList, params)
          if (result.success) {
            const list = result.data
            this.roomlist = Object.values(list).map(item => {
            const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
                return Object.assign({}, item, tmp)
              })
            this.loading = false
          } else {
            this.roomlist = []
            this.loading = false
          }
      }
    },
    getFloorList (typeId) {
      const tempData = this.$store.state.TrainingElearning.commonObj.floorList.filter(item => item.status === 1 && item.room_type_id === parseInt(typeId))
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      // if (orgId) {
      //   return OfficeTypeList.filter(item => item.org_id === orgId)
      // }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
      if (this.formData.training_type_id) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.formData.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async bookingApplicationCheck () {
      if (this.formData.start_date && this.formData.end_date) {
        const roomRent = this.roomRentList.find(item => item.value === parseInt(this.formData.room_rent_id))
        this.formData.room_rent = roomRent !== undefined ? roomRent.own_org_price : ''
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, bookingApplicationCheck, this.formData)
        this.dateErrorMsg = ''
        if (!result.success) {
          this.dateErrorMsg = 'Already booked'
        }
      }
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadinState = { loading: false, listReload: false }
      // const roomRent = this.roomRentList.find(item => item.value === parseInt(this.formData.room_rent_id))
      // this.formData.room_rent = roomRent !== undefined ? roomRent.text : ''
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, bookingApplicationStore, this.formData)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
         this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getRent () {
        RestApi.getData(trainingElearningServiceBaseUrl, roomRentList).then(response => {
            if (response.success) {
                response.data.data.filter(item => item.room_type_id === this.formData.room_type_id)
                if (this.formData.room_type_id === response.data.data.room_type_id) {
                    this.formData.room_rent_id = response.data.data.own_org_price
                }
            } else {
            }
        })
    },
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getAssignedTrainerList () {
        if (this.formData.circular_memo_no) {
            this.loading = true
            const serchData = {
                circular_memo_no: this.formData.circular_memo_no,
                batch_no: this.formData.batch_no
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tpm/driver-assign/assign-trainer-list', serchData)
            if (result.success) {
                this.trainerListData = result.data
                this.loading = false
            } else {
                this.trainerListData = []
                this.loading = false
            }
        }
    },
    getRoomType (id) {
      const obj = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1).find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return obj !== undefined ? obj.text_bn : ''
      } else {
        return obj !== undefined ? obj.text_en : ''
      }
    },
    getRoom (id) {
      const obj = this.$store.state.TrainingElearning.commonObj.roomList.filter(item => item.status === 1).find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return obj !== undefined ? obj.text_bn : ''
      } else {
        return obj !== undefined ? obj.text_en : ''
      }
    },
    getFloorName (id) {
      const obj = this.$store.state.TrainingElearning.commonObj.floorList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return obj !== undefined ? obj.text_bn : ''
      } else {
        return obj !== undefined ? obj.text_en : ''
      }
    }
  }
}
</script>
